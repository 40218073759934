// =============================
// Imports
// =============================

import { required, composeValidators, mustBeEmail, minLength } from '../index';

// =============================
// Validator
// =============================

const validator = {
  email: composeValidators(required, mustBeEmail),
  password: composeValidators(required, minLength(6)),
};

export default validator;
