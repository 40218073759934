// =============================
// Imports
// =============================

// External dependencies
import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { NextSeo } from 'next-seo';

// Config
import { withTranslation } from '../config/i18n';

// Actions
import {
  getUserSessionToken,
  activateUserAccount as activateUserAccountBase,
} from '../store/actions/AuthActions';

// Components
import PublicRoute from '../components/containers/publicRoute';
import Login from '../components/layouts/auth/login';

// Helpers
import * as pth from '../helpers/proptypes';
import { getWebsiteTitle } from '../helpers/misc';

// =============================
// Page
// =============================

class LoginPage extends PureComponent {
  static propTypes = {
    config: pth.config.isRequired,
    isActivatingAccount: PropTypes.bool.isRequired,
    activateUserAccount: PropTypes.func.isRequired,
    routerProps: PropTypes.shape({
      activateToken: PropTypes.string,
    }).isRequired,
    t: PropTypes.func.isRequired,
  };

  static async getInitialProps({ query }) {
    return {
      routerProps: query,
      namespacesRequired: ['common', 'pages', 'errors'],
    };
  }

  componentDidMount() {
    const { routerProps, activateUserAccount } = this.props;

    if (routerProps.activateToken) {
      activateUserAccount(routerProps.activateToken);
    }
  }

  render() {
    const { config, isActivatingAccount, t, ...restProps } = this.props;

    // Do not show login until activation is resolved
    if (isActivatingAccount) return null;

    return (
      <PublicRoute>
        <NextSeo title={getWebsiteTitle(config, t('pages:login.meta_title'))} noindex />
        <Login t={t} config={config} {...restProps} />
      </PublicRoute>
    );
  }
}

function mapStateToProps({ auth }) {
  return {
    isActivatingAccount: auth.activateAccount.isLoading,
  };
}

export default compose(
  withTranslation(['common', 'pages']),
  connect(mapStateToProps, { getUserSessionToken, activateUserAccount: activateUserAccountBase }),
)(LoginPage);
