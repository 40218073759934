// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';

import * as pth from '../../../helpers/proptypes';

// =============================
// Stories
// =============================

class BAM extends Component {
  static propTypes = {
    config: pth.config.isRequired,
    login: PropTypes.bool,
    register: PropTypes.bool,
  };

  static regions = [
    { id: '6571ea0f898dbc39a09912a4', prefix: 'ca', text: '🇨🇦 Canada' },
    { id: '6571ea18898dbc39a09912b2', prefix: 'cn', text: '🇨🇳 China' },
    { id: '6571ea1f181b361ede3280ee', prefix: 'fr', text: '🇫🇷 France' },
    { id: '6571ea2e898dbc39a09915ca', prefix: 'uk', text: '🇬🇧 UK' },
    { id: '6571ea3d85175f8d9598cc3d', prefix: 'us', text: '🇺🇸 US' },
    { id: '6571ea27898dbc39a099131a', prefix: 'intl', text: '🌏 Rest of the world' },
  ];

  currentRegion = (config) => {
    return BAM.regions.find(v => v.id === config.id);
  }

  otherRegions = (config) => {
    return BAM.regions.filter(v => v.id !== config.id);
  }

  render() {
    const { config, login, register } = this.props;

    if (config.tenant !== '607069fad734ad27148d0500') {
      return null;
    }

    const hostname = config.urlConfig.websiteUrl.split('.').slice(1).join('.');
    const currentRegion = this.currentRegion(config);

    if (!currentRegion) {
      return null;
    }

    const otherRegions = this.otherRegions(config);

    return (
      <div style={{
        background: 'rgb(237, 247, 254)',
        padding: '10px',
        borderRadius: '5px',
        lineHeight: '2rem',
      }}>
        {login && (
          <>
            <h1 style={{
              color: 'black',
              fontSize: '1.5rem',
              fontWeight: 'bold',
            }}>Having trouble logging in?</h1>

            <p style={{ marginTop: '5px' }}>To login choose the version of BAM Music website related to your region account.</p>
          </>
        )}

        {register && (
          <>
            <h1 style={{
              color: 'black',
              fontSize: '1.5rem',
              fontWeight: 'bold',
            }}>To sign up, choose the version of BAM Music website related to your location.</h1>
          </>
        )}

        <p style={{ marginTop: '10px' }}>You are currently browsing on the region: <strong>{currentRegion.text}</strong></p>
        <p>Other region available: {otherRegions.map((region, i) => (
          <>
            {(i > 0 ? ' • ' : '')}
            <a href={`https://${region.prefix}.${hostname}`}><strong>{region.text}</strong></a>
          </>
        ))}</p>
      </div>
    );
  }
}

export default BAM;
