// =============================
// Imports
// =============================

import { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';

import * as pth from '../../../../helpers/proptypes';

import MewoInput from '../../../presentationals/mewoInput';
import { Desktop, Mobile } from '../../../other/responsive';

import validator from '../../../../validators/auth/login';

import BAM from '../bam';

import {
  Wrapper,
  AuthTitle,
  InputWrapper,
  ButtonWrapper,
  Container,
  LinksWrapper,
  StyledLink,
  StyledInput,
  StyledButton,
  Notification,
  StyledUserLanguages,
  StyledForm,
  ButtonLink,
} from '../common.styles';

// =============================
// Stories
// =============================

class Login extends Component {
  static propTypes = {
    getUserSessionToken: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    config: pth.config.isRequired,
  };

  render() {
    const { getUserSessionToken, t, config } = this.props;

    return (
      <Wrapper>
        <Fragment>
          <Container>
            <Form onSubmit={getUserSessionToken}>
              {({ submitError, handleSubmit, submitting }) => (
                <StyledForm onSubmit={handleSubmit}>
                  <AuthTitle>{t('pages:login.title')}</AuthTitle>
                  {submitError && <Notification type="error">{submitError}</Notification>}
                  <InputWrapper>
                    <Field
                      name="email"
                      component={MewoInput}
                      use={StyledInput}
                      type="text"
                      placeholder={t('common:form.email')}
                      validate={validator.email}
                    />
                    <Field
                      name="password"
                      component={MewoInput}
                      use={StyledInput}
                      type="password"
                      placeholder={t('common:form.password')}
                      validate={validator.password}
                    />
                  </InputWrapper>
                  <LinksWrapper>
                    <StyledLink route="/recover">{t('pages:login.forgot_password')}</StyledLink>
                  </LinksWrapper>
                  <ButtonWrapper>
                    <Mobile>
                      <StyledLink mt="2rem" route="/register">{t('pages:login.register')}</StyledLink>
                    </Mobile>
                    <Desktop>
                      <ButtonLink route="/register">
                        <StyledButton fillVariant="outline">{t('pages:login.register')}</StyledButton>
                      </ButtonLink>
                    </Desktop>
                    <StyledButton disabled={submitting} firstOnMobile type="submit">
                      {t('pages:login.login')}
                    </StyledButton>
                  </ButtonWrapper>
                </StyledForm>
              )}
            </Form>

            <BAM config={config} login />

            <Mobile>
              <StyledUserLanguages opened />
            </Mobile>
          </Container>
          <Desktop>
            <StyledUserLanguages opened />
          </Desktop>
        </Fragment>
      </Wrapper>
    );
  }
}

export default Login;
