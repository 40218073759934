// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Router from 'next/router';

import * as LastRoute from '../../../helpers/lastroute';
import { rootRoute } from '../../../helpers/config';
import { hasAccessToPrivate } from '../../../helpers/user';
import * as pth from '../../../helpers/proptypes';

// =============================
// Component
// =============================

class PublicRoute extends Component {
  static propTypes = {
    config: pth.config.isRequired,
    isLogged: PropTypes.bool.isRequired,
    /** If True, has access to website when private. */
    hasAccess: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
  };

  componentDidMount() {
    const { config, isLogged, hasAccess } = this.props;

    if (isLogged && hasAccess) {
      const { lastRoute, lastAsPath } = LastRoute.get(rootRoute(config));

      Router.push(lastRoute, lastAsPath);
    }
  }

  componentDidUpdate(prevProps) {
    const { config, isLogged, hasAccess } = this.props;

    if ((!prevProps.isLogged || !prevProps.hasAccess) && isLogged && hasAccess) {
      const { lastRoute, lastAsPath } = LastRoute.get(rootRoute(config));

      Router.push(lastRoute, lastAsPath);
    }
  }

  render() {
    const { isLogged, hasAccess, children } = this.props;

    if (isLogged && hasAccess) return null;

    return children;
  }
}

function mapStateToProps(store) {
  const { config, user } = store;

  return {
    config: config.data,
    isLogged: user.isLogged,
    /** If True, has access to website when private. */
    hasAccess: hasAccessToPrivate(user, config),
  };
}

export default connect(mapStateToProps)(PublicRoute);
